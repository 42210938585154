<template>
  <div>
    <!-- User Info: Input Fields -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <!-- Field: Status -->
          <b-col cols="12" md="4">
            <b-form-group label="City" label-for="user-status">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="City"
              >
                <v-select
                  v-model="model.cityId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="cityOptions"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="user-status"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Field: Username -->
          <b-col cols="12" md="4">
            <b-form-group label="Name Office" label-for="nameCountry">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Name Office"
              >
                <b-form-input id="username" v-model="model.name" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Status -->
          <b-col cols="12" md="4">
            <b-form-group label="Manager" label-for="user-status">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Manager"
              >
                <v-select
                  v-model="model.employee"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="employeeOptions"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="user-status"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Adress -->
          <b-col cols="12" md="4">
            <b-form-group label="Adress" label-for="nameCountry">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Adress"
              >
                <b-form-input id="username" v-model="model.adress" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Zip Code -->
          <b-col cols="12" md="4">
            <b-form-group label="Zip Code" label-for="nameCountry">
              <validation-provider
                #default="{ errors }"
                rules="required|integer"
                name="Zip Code"
              >
                <b-form-input id="username" v-model="model.zip_code" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Status -->
          <b-col cols="12" md="4">
            <b-form-group label="Status" label-for="user-status">
              <v-select
                v-model="model.status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="user-status"
              />
            </b-form-group>
          </b-col>
          <!-- Field: Name Pos -->
          <b-col cols="12" md="4">
            <b-form-group label="Name Pos" label-for="user-status">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Pos"
              >
                <v-select
                  v-model="model.posCodeId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="posOptions"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="user-status"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Field: Pos Id -->
          <b-col cols="12" md="4">
            <b-form-group label="Pos Id" label-for="nameCountry">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Code Pos"
              >
                <b-form-input id="username" v-model="model.posId" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click.prevent="validationForm"
    >
      Create Office
    </b-button>
    <b-button
      variant="outline-secondary"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="reset"
    >
      Reset
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from "@validations";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import router from "@/router";
import store from "@/store";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          // eslint-disable-next-line
          this.createCountry();
        }
      });
    },
  },
  setup(props) {
    const toast = useToast();

    const statusOptions = [
      { label: "Active", value: true },
      { label: "Inactive", value: false },
    ];
    const cityOptions = ref([]);
    const employeeOptions = ref([]);
    const posOptions = ref([]);

    const model = ref({
      name: "",
      status: true,
      cityId: "",
      employee: "",
      adress: "",
      zip_code: "",
      posId: "",
      posCode: "",
    });
    const userData = ref(null);
    const city = [];
    const employees = [];
    const posData = [];
    store
      .dispatch("app-user/fetchHelpers")
      .then((response) => {
        const { cities, employee, pos } = response.data.data;

        cities.map((data, item) => {
          city.push({ label: data.name, value: data.id, id: item });
        });

        employee.map((data, item) => {
          employees.push({
            label: `${data.first_name} ${data.last_name}`,
            value: data.id,
            id: item,
          });
        });

        pos.map((data, item) => {
          posData.push({ label: data.name, value: data.id, id: item });
        });

        cityOptions.value = city;
        employeeOptions.value = employees;
        posOptions.value = posData;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          userData.value = undefined;
        }
      });
    const createCountry = () => {
      store
        .dispatch("app-user/addCountry", { model })
        .then((response) => {
          userData.value = response.data;
          toast({
            component: ToastificationContent,
            props: {
              title: `The office ${model.value.name} was created correctly`,
              icon: "AlertTriangleIcon",
              variant: "success",
            },
          });
          router.push({ name: "apps-office-list" });
        })
        .catch((error) => {
          if (error.response.status === 404) {
            userData.value = undefined;
          }
        });
    };

    const reset = () => {
      model.value.name = "";
      model.value.status = "";
    };

    return {
      avatarText,
      statusOptions,
      cityOptions,
      posOptions,
      employeeOptions,
      model,

      createCountry,
      reset,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
